/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { detect } from 'detect-browser';
import { useDispatch, useSelector } from 'react-redux';

import storeAnalyticsMetadata from './apolloMetadata';
import { updateUserLocation } from '../reducers/user';

const useGetUserLocationDetails = () => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);

  const getUserLocationDetails = async () => {
    try {
      if (userLocation && Object.keys(userLocation).length) return userLocation;
      const res = await axios.get('https://ipapi.co/json/', { withCredentials: false });
      dispatch(updateUserLocation(res));
      return res;
    } catch (err) {
      return err;
    }
  };

  return getUserLocationDetails;
};

const useFormatAnalyticsData = () => {
  const getUserLocationDetails = useGetUserLocationDetails();

  const formatAnalyticsData = async (
    email,
    clientId,
    eventName,
    currentScreen,
    applicationStatus = ''
  ) => {
    const browser = detect();
    const locDetails = await getUserLocationDetails();
    const rudderStackObj = {
      clientId,
      country: locDetails?.data?.country_name,
      ipAddress: locDetails?.data?.ip,
      device: navigator.platform,
      browser: browser.name,
      browserVersion: browser.version,
      eventName,
      userEmail: email,
      timeStamp: new Date().getTime(),
      currentScreen,
      product: 'dashboard'
    };
    if (applicationStatus !== '') {
      rudderStackObj.applicationStatus = applicationStatus;
    }
    storeAnalyticsMetadata(rudderStackObj);
  };

  return formatAnalyticsData;
};

export { useFormatAnalyticsData };
