import React, { useState } from 'react';

import { trim } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionWrapper } from 'storybook-ui-components';

import Body from './Body';
import Footer from './Footer';
import listUsers, { bulkOnboardUsers } from '../../../../actions/userManagement';
import analyticsEvents from '../../../../constants/analyticsEvents';
import CustomConnect from '../../../../containers/HOC/CustomConnect';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';
import { closeModal } from '../../../../reducers/modal';
import { DEFAULT_AUTHORIZED_PRODUCT } from '../../../../utils/constants';
import { useFormatAnalyticsData } from '../../../../utils/customHooks';
import doesArrayHaveDuplicates from '../../../../utils/helpers';
import CustomModal from '../../../Common/Modal/CustomModal';

import './InviteUsers.scss';

// Update If Advanced Check Required
const emailValidationRegex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,12}$/;
const emailRawTextSplitRegex = /,\n|\n|,/;

function InviteUsers({ bulkOnboardUsers, listUsers }) {
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(DEFAULT_AUTHORIZED_PRODUCT);
  const [emailRawText, setEmailRawText] = useState('');
  const [emailInvalidMessage, setEmailInvalidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [didFailPartially, setDidFailPartially] = useState(false);
  const [partialErrorData, setPartialErrorData] = useState([]);
  const { email, clientId } = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.modal);
  const { modalId } = modalData || {};
  const isOpen = modalId === 'inviteUsers';
  const formatAnalyticsData = useFormatAnalyticsData();

  const resetFormFields = () => {
    setSelectedRole('');
    setEmailRawText('');
    setEmailInvalidMessage('');
    setDidFailPartially(false);
    setPartialErrorData([]);
  };

  const closeHandler = (e, clearFields = false) => {
    if (clearFields) {
      resetFormFields();
    }
    dispatch(closeModal());
  };

  const filters = useSelector((state) => state.userManagement.filters);
  const tableMeta = useSelector((state) => state.userManagement.tableMeta);
  const { page, sortBy } = tableMeta || {};
  const listUserReqData = {
    filters,
    pageNumber: page,
    sortBy
  };

  const handleSelect = (value) => {
    setSelectedRole(value);
  };

  const handleTextAreaType = (event) => {
    setEmailRawText(event.target.value);
    setEmailInvalidMessage('');
  };

  const resetPartialFail = () => {
    setDidFailPartially(false);
  };

  const inviteUsersHandler = async (emailList, selectedRole, selectedProducts) => {
    setIsLoading(true);
    const userList = emailList.map((email) => ({
      email,
      name: email,
      authorizedProducts: selectedProducts
    }));
    const { success, partialError } = await bulkOnboardUsers({
      userList,
      iamRole: selectedRole
    });
    formatAnalyticsData(email, clientId, analyticsEvents.DASHBOARD_USER_INVITE_USER_SEND, 'User');
    setIsLoading(false);
    if (success === 'all') {
      listUsers(listUserReqData);
      closeHandler(null, true);
    } else if (success === 'partial') {
      listUsers(listUserReqData);
      setDidFailPartially(true);
      setPartialErrorData(partialError || []);
    }
  };

  const handleConfirmClick = async () => {
    let emailList = emailRawText.split(emailRawTextSplitRegex);
    emailList = emailList.map(trim).filter(Boolean);
    const isEmailListInvalid = emailList.some((email) => !emailValidationRegex.test(email));
    if (isEmailListInvalid) {
      setEmailInvalidMessage('Invalid Email Found');
      return;
    }
    if (doesArrayHaveDuplicates(emailList)) {
      setEmailInvalidMessage('Duplicate Email Found');
      return;
    }
    await inviteUsersHandler(
      emailList,
      selectedRole,
      selectedProducts.length === 0
        ? DEFAULT_AUTHORIZED_PRODUCT.map((product) => product.value)
        : selectedProducts.map((product) => product.value)
    );
  };

  return (
    <CustomModal id="invite_users_modal" isOpen={isOpen} closeHandler={closeHandler}>
      <div id="invite_users_modal__container">
        <div id="invite_users_modal__header">
          <h1>Invite Members</h1>
        </div>
        <Body
          isLoading={isLoading}
          didFailPartially={didFailPartially}
          partialErrorData={partialErrorData}
          emailInvalidMessage={emailInvalidMessage}
          selectedRole={selectedRole}
          emailRawText={emailRawText}
          handleSelect={handleSelect}
          handleTextAreaType={handleTextAreaType}
          selectedProducts={selectedProducts}
          handleSelectedProduct={setSelectedProducts}
        />
        <Footer
          isFormEmpty={!selectedRole || !emailRawText}
          isLoading={isLoading}
          didFailPartially={didFailPartially}
          closeHandler={closeHandler}
          resetPartialFail={resetPartialFail}
          handleConfirmClick={handleConfirmClick}
        />
      </div>
    </CustomModal>
  );
}

const mapHooksToActions = {
  bulkOnboardUsers,
  listUsers
};

InviteUsers.propTypes = {
  bulkOnboardUsers: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired
};

export default PermissionWrapper(
  CustomConnect(InviteUsers, { mapHooksToActions }),
  useGetUserPermissions,
  getPermission('bulkOnboard')
);
