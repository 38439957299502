import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionFilterListWrapper } from 'storybook-ui-components';

import { updateUserStatus } from '../../../../../actions/userManagement';
import analyticsEvents from '../../../../../constants/analyticsEvents';
import CustomConnect from '../../../../../containers/HOC/CustomConnect';
import useGetUserPermissions from '../../../../../Permissions/hooks';
import getPermission from '../../../../../Permissions/mapping';
import { openModal } from '../../../../../reducers/modal';
import { useFormatAnalyticsData } from '../../../../../utils/customHooks';
import AddToolTipOnDisable from '../../../../Common/AddToolTipOnDisable';

function OptionsCell({ name, email, role, updateUserStatus, status, authorizedProducts }) {
  const dispatch = useDispatch();
  const userPermissions = useGetUserPermissions();
  const { email: userEmail, clientId } = useSelector((state) => state.user.userDetails);
  const formatAnalyticsData = useFormatAnalyticsData();

  const editUser = (disable) => {
    if (disable) return;
    dispatch(
      openModal({
        modalId: 'editUser',
        inputData: {
          name,
          email,
          role,
          authorizedProducts
        }
      })
    );
    formatAnalyticsData(
      userEmail,
      clientId,
      analyticsEvents.DASHBOARD_USER_EDIT_USER_CLICK,
      'User'
    );
  };
  const updateStatus = async (disable) => {
    if (disable) return;
    formatAnalyticsData(
      userEmail,
      clientId,
      analyticsEvents.DASHBOARD_USER_UPDATE_USER_STATUS_CLICK,
      'User'
    );
    await updateUserStatus({
      email,
      isDisabled: status !== 'user_disabled'
    });
  };

  let options = [
    {
      key: 'editUser',
      value: 'Edit User',
      disable: false,
      permissionObject: getPermission('editUser'),
      onClick: editUser
    },
    {
      key: status === 'user_disabled' ? 'reactivate' : 'deactivate',
      value: status === 'user_disabled' ? 'Reactivate' : 'Deactivate',
      disable: false,
      permissionObject: getPermission('changeUserStatus'),
      onClick: updateStatus
    }
  ];
  options = PermissionFilterListWrapper(options, userPermissions);

  return (
    <div className="options_select_menu">
      {options.map(({ key, value, disable, onClick }) => (
        <AddToolTipOnDisable key={value} isDisabled={disable}>
          <div
            className={`options_select_menu_item ${disable && 'disable'}`}
            key={key}
            onClick={() => onClick(disable)}
            aria-hidden>
            {value}
          </div>
        </AddToolTipOnDisable>
      ))}
    </div>
  );
}

const mapHooksToActions = {
  updateUserStatus
};

OptionsCell.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  authorizedProducts: PropTypes.array.isRequired
};

export default CustomConnect(OptionsCell, { mapHooksToActions });
